import React from 'react'
import {
    FaBullhorn,
    FaChartLine,
    FaGoogle,
    FaReact,
    FaRocket,
    // FaUserPlus,
    FaWordpress,
} from 'react-icons/fa'

import assistant from 'assets/images/assistant.jpg'
import pressjsLogo from 'assets/images/pressjs-logo.svg'
import responsive from 'assets/images/responsive-webpage.png'

export default function App() {
    return (
        <>
            <Top pressjsLogo={pressjsLogo} />
            <MeetsAGuide assistant={assistant} />
            <ThatEndsInASuccess responsive={responsive} />
            <Testimonials />
            <HasAPlan />
            <TechFacts
                FaReact={FaReact}
                FaGoogle={FaGoogle}
                FaWordpress={FaWordpress}
            />
            <Footer />
            <Registration />
            <div
                id="offcanvas-nav"
                data-uk-offcanvas="flip: true; overlay: false"
            >
                <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
                    <button
                        className="uk-offcanvas-close uk-close uk-icon"
                        type="button"
                        data-uk-close
                    ></button>
                    <ul className="uk-nav uk-nav-default">
                        <li>
                            <a
                                href="#ThatEndsInASuccess"
                                data-uk-scroll="duration: 500"
                            >
                                Action
                            </a>
                        </li>
                        <li className="uk-parent">
                            <a href="#HasAPlan" data-uk-scroll="duration: 500">
                                Plan
                            </a>
                        </li>
                        <li>
                            <a href="#TechFacts" data-uk-scroll="duration: 500">
                                Tech
                            </a>
                        </li>
                        <li>
                            <a
                                href="#Testimonials"
                                data-uk-scroll="duration: 500"
                            >
                                Testimonials
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <style>
                {`
                .subtitle-text {
                    font-weight: 400;
                    opacity: 1;
                }
                @media only screen and (min-width: 1200px) {
                    .uk-margin-remove-top@l {
                        margin-top: 0 !important;
                    }
                }
            `}
            </style>
        </>
    )
}

function Top({ pressjsLogo }) {
    return (
        <div
            id="Top"
            className="top-wrap uk-position-relative uk-background-secondary"
        >
            <Navbar pressjsLogo={pressjsLogo} />
            <Hero />
        </div>
    )
}

function CallToAction() {
    return (
        <button
            href="#Registration"
            className="uk-button uk-button-primary uk-border-rounded uk-icon uk-box-shadow-small uk-scrollspy-inview uk-animation-fade uk-animation-slide-right-medium"
            data-uk-icon="arrow-right"
            data-uk-scrollspy-className="uk-animation-fade"
            data-uk-toggle="target: #Registration"
            title="Create your free blog"
        >
            Create your free blog
        </button>
    )
}

function Navbar({ pressjsLogo }) {
    return (
        <div
            className="nav uk-sticky"
            data-uk-sticky="cls-active: uk-background-secondary uk-box-shadow-medium; top: 100vh; animation: uk-animation-slide-top"
        >
            <div className="uk-container">
                <nav
                    className="uk-navbar uk-navbar-container uk-navbar-transparent"
                    data-uk-navbar=""
                >
                    <div className="uk-navbar-left">
                        <div className="uk-navbar-item uk-padding-remove-horizontal">
                            <a
                                className="uk-logo"
                                title="Logo"
                                href="#Top"
                                data-uk-scroll="duration: 500"
                            >
                                <img src={pressjsLogo} alt="Logo" />
                            </a>
                        </div>
                    </div>
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav uk-light uk-visible@s">
                            <li>
                                <a
                                    href="#ThatEndsInASuccess"
                                    data-uk-scroll="duration: 500"
                                >
                                    Action
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#HasAPlan"
                                    data-uk-scroll="duration: 500"
                                >
                                    Plan
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#TechFacts"
                                    data-uk-scroll="duration: 500"
                                >
                                    Tech
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#Testimonials"
                                    data-uk-scroll="duration: 500"
                                >
                                    Testimonials
                                </a>
                            </li>
                        </ul>
                        <div
                            className="uk-visible@m"
                            style={{ marginLeft: '1rem' }}
                        >
                            <CallToAction />
                        </div>
                        <a
                            className="uk-navbar-toggle uk-navbar-item uk-hidden@s uk-icon uk-navbar-toggle-icon"
                            data-uk-toggle=""
                            data-uk-navbar-toggle-icon=""
                            href="#offcanvas-nav"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                data-svg="navbar-toggle-icon"
                            >
                                <rect y="9" width="20" height="2"></rect>
                                <rect y="3" width="20" height="2"></rect>
                                <rect y="15" width="20" height="2"></rect>
                            </svg>
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    )
}

function Hero() {
    return (
        <>
            <div
                id="Hero"
                className="uk-cover-container uk-flex uk-flex-middle top-wrap-height"
            >
                {/* TOP CONTAINER */}
                <div
                    className="uk-container uk-flex-auto top-container uk-position-relative uk-margin-medium-top"
                    data-uk-parallax="y: 0,50; easing:0; opacity:0.2"
                    style={{
                        transform: 'translateY(0px)',
                        opacity: 1,
                    }}
                >
                    <div
                        className="uk-width-1-2@s"
                        data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
                    >
                        <div className="uk-light">
                            <h6 className="uk-text-primary uk-margin-small-bottom uk-scrollspy-inview uk-animation-slide-right-medium">
                                GROW
                            </h6>
                            <h1 className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-right-medium">
                                Drive Traffic To Your React App With A Blog.
                            </h1>
                            <p
                                className="subtitle-text uk-visible@s uk-scrollspy-inview uk-animation-slide-right-medium"
                                style={{
                                    color: 'white',
                                    fontWeight: 400,
                                    opacity: 1,
                                }}
                            >
                                Add a complete blogging solution to your React
                                app in no time. Improve your SEO. Connect with
                                your customers. Grow your app!
                            </p>
                        </div>
                        <CallToAction />
                    </div>
                </div>
                {/* /TOP CONTAINER */}
                {/* TOP IMAGE */}
                <img
                    src="https://images.pexels.com/photos/3153201/pexels-photo-3153201.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                    data-sizes="100vw"
                    data-src="https://images.pexels.com/photos/3153201/pexels-photo-3153201.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                    alt=""
                    data-uk-cover=""
                    data-uk-img=""
                    data-uk-parallax="opacity: 1,0.1; easing:0"
                    className="uk-cover"
                    sizes="100vw"
                    style={{
                        height: 691,
                        width: 948,
                        opacity: 1,
                    }}
                />
                {/* /TOP IMAGE */}
            </div>
            <div className="uk-light uk-position-bottom-center uk-position-medium uk-position-z-index uk-text-center">
                <a
                    href="#MeetsAGuide"
                    data-uk-scroll="duration: 500"
                    data-uk-icon="icon: arrow-down; ratio: 2"
                    className="uk-icon"
                >
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        data-svg="arrow-down"
                    >
                        <polygon points="10.5,16.08 5.63,10.66 6.37,10 10.5,14.58 14.63,10 15.37,10.66"></polygon>
                        <line
                            fill="none"
                            stroke="#000"
                            x1="10.5"
                            y1="4"
                            x2="10.5"
                            y2="15"
                        ></line>
                    </svg>
                </a>
            </div>
        </>
    )
}

function MeetsAGuide({ assistant }) {
    return (
        <section
            id="MeetsAGuide"
            className="uk-section uk-section-default uk-section-large"
        >
            <div className="uk-container">
                <div className="uk-grid uk-child-width-1-2@l uk-flex-middle">
                    <div>
                        <h6>SIMPLIFY</h6>
                        <h2 className="uk-margin-small-top uk-h1">
                            Adding a blog to your React app should be easy.
                        </h2>
                        <p className="subtitle-text">
                            It should never feel frustrating due to:
                        </p>
                        <ul className="uk-list uk-list-bullet subtitle-text">
                            <li>
                                Complex frameworks that have a steep learning
                                curve
                            </li>
                            <li>Difficult to manage metadata</li>
                            <li>
                                Tedious publishing workflows with markdown files
                            </li>
                        </ul>
                        <CallToAction />
                    </div>
                    <div
                        data-uk-scrollspy="cls: uk-animation-fade"
                        className="uk-flex uk-flex-center uk-margin-large-top uk-scrollspy-inview uk-animation-fade uk-margin-remove-top@l"
                    >
                        <img
                            className="uk-border-rounded uk-box-shadow-small uk-scrollspy-inview uk-animation-fade"
                            src={assistant}
                            data-src={assistant}
                            data-uk-img=""
                            alt="Happy Programmer"
                        />
                    </div>
                </div>
            </div>
            <div className="uk-container uk-text-center uk-section">
                <h3 className="uk-margin-remove uk-h2">
                    At PressJS, we believe adding a blog to your React app
                    shouldn’t take ten months, force you to use a framework, or
                    stress you out.
                </h3>
            </div>
        </section>
    )
}

function ToActionCall({ children, headline, body }) {
    return (
        <li className="ToActionCall uk-margin-large-bottom">
            <div>
                <span className="branding-bullet">{children}</span>
            </div>
            <div>
                <h5 className="uk-margin-small-bottom">
                    <strong>{headline}</strong>
                </h5>
                <div>{body}</div>
            </div>
            <style>
                {`
                .ToActionCall {
                    display: flex;
                    align-items: baseline;
                }
                .branding-bullet {
                    display: inline-block;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    margin-right: 0.75rem;
                    color: #E6005C;
                    background-color: #FFBFD9;
                    border-radius: 9999px;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                }
                .ToActionCall.uk-margin-large-bottom:last-child {
                    margin-bottom: 0 !important;
                }
            `}
            </style>
        </li>
    )
}

function ThatEndsInASuccess({ responsive }) {
    return (
        <section
            id="ThatEndsInASuccess"
            className="uk-section uk-section-default uk-section-large uk-section-muted"
        >
            <div className="uk-container uk-container-xsmall uk-text-center uk-section uk-padding-remove-top">
                <h5 className="uk-text-primary">ACTION</h5>
                <h2 className="uk-margin-remove uk-h1">
                    Upgrade Your Blog
                    <br />
                    Grow Your App
                </h2>
            </div>
            <div className="uk-container">
                <div className="uk-grid uk-flex-middle">
                    <div
                        data-uk-scrollspy="cls: uk-animation-fade"
                        className="uk-flex uk-flex-center uk-scrollspy-inview uk-animation-fade uk-width-3-5@m"
                    >
                        <img
                            className="uk-scrollspy-inview uk-animation-fade"
                            src={responsive}
                            data-src={responsive}
                            data-uk-img=""
                            alt=""
                        />
                    </div>
                    <div className="uk-width-2-5@m">
                        <ul className="CallsThemToAction">
                            <ToActionCall
                                headline="Get more users"
                                body="With a blog you show your expertise. And help your users to
                    trust you."
                            >
                                <FaChartLine />
                            </ToActionCall>
                            <ToActionCall
                                headline="Fuel your SEO"
                                body="Google loves fresh content. Every time you write a post you create fresh content."
                            >
                                <FaRocket />
                            </ToActionCall>
                            <ToActionCall
                                headline="Give your app a voice"
                                body="A blog generates content to share on social media. Which creates new opportunities for user engagement."
                            >
                                <FaBullhorn />
                            </ToActionCall>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="uk-container uk-text-center uk-section">
                <h3 className="uk-margin-remove uk-h2">
                    <CallToAction />
                </h3>
            </div>
        </section>
    )
}

function Testimonials() {
    return (
        <section id="Testimonials" className="uk-cover-container overlay-wrap">
            <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-srcset="https://picsum.photos/640/650/?image=770 640w,
			https://picsum.photos/960/650/?image=770 960w,
			https://picsum.photos/1200/650/?image=770 1200w,
			https://picsum.photos/2000/650/?image=770 2000w"
                data-sizes="100vw"
                data-src="https://picsum.photos/1200/650/?image=770"
                alt=""
                data-uk-cover
                data-uk-img
            />
            <div className="uk-container uk-position-z-index uk-position-relative uk-section uk-section-xlarge uk-light">
                <div className="uk-grid uk-flex-right">
                    <div
                        className="uk-width-1-2@m"
                        data-uk-parallax="y: 50,-50; easing: 0; media:@l"
                    >
                        <h6>TESTIMONIALS</h6>
                        <div
                            className="uk-position-relative uk-visible-toggle uk-light"
                            data-uk-slider="easing: cubic-bezier(.16,.75,.47,1)"
                        >
                            <ul className="uk-slider-items uk-child-width-1-1">
                                <li>
                                    <div data-uk-slider-parallax="opacity: 0.2,1,0.2">
                                        <div
                                            id="author-wrap"
                                            className="uk-container uk-container-small"
                                        >
                                            <div
                                                className="uk-grid uk-grid-medium uk-flex"
                                                data-uk-grid
                                            >
                                                <div className="uk-width-auto">
                                                    <img
                                                        src="https://probao.de/wp-content/uploads/weingarten-3-150x150.jpg.webp"
                                                        alt="Marco Laspe"
                                                        className="uk-border-circle"
                                                        height="150"
                                                        width="150"
                                                    />
                                                </div>
                                                <div className="uk-width-expand">
                                                    <h2 className="uk-margin-remove-top">
                                                        "Finally an easy way to
                                                        integrate a blog with
                                                        our app and get in touch
                                                        with our users."
                                                    </h2>
                                                    <span className="uk-text-meta">
                                                        Marco Laspe, founder of{' '}
                                                        <a
                                                            href="https://www.fulcrum.wiki"
                                                            title="A wiki for Google Drive"
                                                        >
                                                            Fulcrum Wiki
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <h2 className="uk-margin-small-top">
                     "Finally an easy way to
                     integrate a blog with our app
                     and get in touch with our
                     users."
                  </h2>
                  <p className="uk-text-meta">
                     Marco Laspe, founder of{' '}
                     <a
                         href="https://www.fulcrum.wiki"
                         title="A wiki for Google Drive"
                     >
                         Fulcrum Wiki
                     </a>
                  </p> */}
                                    </div>
                                </li>
                            </ul>
                            <ul className="uk-slider-nav uk-dotnav uk-margin-top">
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function PlanStep({ body, number, shadow = false, title }) {
    return (
        <div style={{ marginTop: '2rem' }}>
            <div
                className="uk-card uk-card-default uk-card-large uk-card-body"
                style={
                    shadow
                        ? {
                              boxShadow: '0 14px 25px rgba(0,0,0,0.16)',
                              marginBottom: '2rem',
                          }
                        : { marginBottom: '2rem' }
                }
            >
                <div className="PlanStep_number">{number}</div>
                <h3 className="uk-card-title">{title}</h3>
                <p>{body}</p>
                <p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 70 70"
                        id="1368646338"
                        className="svg u_1368646338"
                        data-icon-name="ar-arrow_long_right"
                        style={{
                            color: '#ff0066',
                            fill: '#ff0066',
                            width: 50,
                        }}
                    >
                        {' '}
                        <g id="1099322994">
                            {' '}
                            <g id="1934795770">
                                {' '}
                                <g id="1767340827">
                                    {' '}
                                    <g id="1884441831">
                                        {' '}
                                        <polygon
                                            points="48.3,26.8 46.9,28.2 54.2,35 46.9,41.8 48.3,43.2 57.1,35 				"
                                            id="1348025797"
                                        ></polygon>
                                    </g>
                                </g>
                            </g>
                            <g id="1920251342">
                                {' '}
                                <rect
                                    x="12.9"
                                    y="34"
                                    width="42.2"
                                    height="2"
                                    id="1906799123"
                                ></rect>
                            </g>
                        </g>
                    </svg>
                </p>
            </div>
        </div>
    )
}

function HasAPlan() {
    return (
        <section
            id="HasAPlan"
            className="uk-section uk-section-default uk-padding-remove-bottom"
        >
            <div className="uk-container uk-container-xsmall uk-text-center uk-section">
                <h5 className="uk-text-primary">A PLAN</h5>
                <h2 className="uk-margin-remove uk-h1">
                    3 Simple Steps to a Blog You Love
                </h2>
            </div>
            <div className="uk-container">
                <div
                    className="uk-grid uk-grid-large uk-child-width-1-3@m"
                    data-uk-grid
                    data-uk-scrollspy="target: > div; delay: 150; cls: uk-animation-slide-bottom-medium"
                >
                    <PlanStep
                        body={
                            'Click "Create Your Free Blog" to open a free account and create your content.'
                        }
                        number="1"
                        shadow={true}
                        title="Create an account"
                    />
                    <PlanStep
                        body={
                            'Use our premade blog component with different beautiful designs.'
                        }
                        number="2"
                        title="Add a blog"
                    />
                    <PlanStep
                        body={
                            'Enjoy getting new users and even more important engage with existing ones.'
                        }
                        number="3"
                        title="Grow your app"
                    />
                </div>
            </div>

            <div className="uk-container uk-text-center uk-section">
                <h3 className="uk-margin-remove uk-h2">
                    <CallToAction />
                </h3>
            </div>
            <style>
                {`
                    .PlanStep_number {
                        align-items: center;
                        color: white;
                        background-color: #ff0066;
                        border-radius: 50%;
                        display: flex;
                        font-size: 3rem;
                        font-weight: 400;
                        height: 96px;
                        justify-content: center;
                        left: -1rem;
                        min-width: 96px;
                        position: absolute;
                        top: -2rem;
                        width: 96px;
                    }
                `}
            </style>
        </section>
    )
}

function TechFact({ backgroundColor, body, color, icon, title }) {
    const Icon = icon
    return (
        <div className="uk-text-center">
            <div className="uk-card uk-card-default">
                <div className="uk-card-body">
                    <div
                        className="uk-border-circle"
                        style={{
                            backgroundColor: backgroundColor,
                            color: color,
                            height: '2.5rem',
                            margin: '0 auto',
                            padding: '1rem',
                            width: '2.5rem',
                        }}
                    >
                        <Icon size="2.5rem" />
                    </div>
                    <h4>
                        <strong>{title}</strong>
                    </h4>
                    <p>{body}</p>
                </div>
            </div>
        </div>
    )
}

function TechFacts({ FaReact, FaGoogle, FaWordpress }) {
    return (
        <section id="TechFacts" className="uk-section uk-section-default">
            <div className="uk-container uk-container-xsmall uk-text-center uk-section">
                <h5 className="uk-text-primary">EVERY BLOG USES</h5>
                <h2 className="uk-margin-remove uk-h1">
                    The Technology You Love
                </h2>
            </div>
            <div className="uk-container">
                <div
                    className="uk-grid uk-grid-large uk-child-width-1-3@m"
                    data-uk-grid
                    data-uk-scrollspy="target: > div; delay: 150; cls: uk-animation-slide-bottom-medium"
                >
                    <TechFact
                        backgroundColor="rgb(48, 56, 70)"
                        body="With PressJS there is no need to use complex React frameworks like GatsbyJS or Next.js. PressJS works with every React app."
                        color="rgb(9, 211, 172)"
                        icon={FaReact}
                        title="Works with Create React App"
                    />
                    <TechFact
                        backgroundColor="rgb(66, 133, 244)"
                        body="With PressJS you create SEO-friendly posts that rank easily on Google. PressJS lets you manage title and meta-tags for every page."
                        color="white"
                        icon={FaGoogle}
                        title="Loves Google"
                    />
                    <TechFact
                        backgroundColor="#0073aa"
                        body="Play it save with the power of the time tested Wordpress backend. With all its plugins it satisfies all your current and future needs."
                        color="white"
                        icon={FaWordpress}
                        title="Builds on Wordpress
                            "
                    />
                </div>
            </div>

            <div className="uk-container uk-text-center uk-section">
                <h3 className="uk-margin-remove uk-h2">
                    <CallToAction />
                </h3>
            </div>
        </section>
    )
}

function Footer() {
    return (
        <footer className="uk-section uk-section-secondary uk-padding-remove-bottom">
            <div className="uk-container">
                <div className="uk-grid uk-grid-large" data-uk-grid>
                    <div className="uk-width-1-2@m">
                        <a
                            className="uk-logo"
                            title="Logo"
                            href="#Top"
                            data-uk-scroll="duration: 500"
                        >
                            <img width="100" src={pressjsLogo} alt="Logo" />
                        </a>
                    </div>
                    <div className="uk-width-1-6@m"></div>
                    <div className="uk-width-1-6@m"></div>
                    <div className="uk-width-1-6@m"></div>
                </div>

                <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                    <span className="uk-text-small uk-text-muted">
                        © 2020 PressJS -{' '}
                        <a href="https://github.com/zzseba78/Kick-Off">
                            Based on KickOff
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    )
}

function Registration() {
    return (
        <div id="Registration" data-uk-modal>
            <div
                className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body"
                style={{
                    backgroundColor: '#EFF2F7',
                }}
            >
                <button
                    className="uk-modal-close-default"
                    type="button"
                    data-uk-close
                ></button>
                <iframe
                    id="registration-frame"
                    title="Registration"
                    width="540"
                    height="620"
                    src="https://3c2feb86.sibforms.com/serve/MUIEAFdq3FTAm5j0j3dZXl5aO4ngtjXTQMxdiTkHr-Pb61013C5GebFoNblGhCPcweeEy_9yaDslxcu11S9gwNPtemMt0ZXUrNYIjmFEqnTRRfLzAA_5SE9QZgWzcLATjT5KU3DCuK75_RpAKCBUcG8pQ7gkmMEAtlPko50xjxiybitR1vGEvKDk_X9ToLHYW6iKZyYXoTnKmK3t"
                    frameborder="0"
                    scrolling="auto"
                    allowfullscreen
                    style={{
                        display: 'block',
                        margin: 'auto',
                        maxWwidth: '100%',
                    }}
                ></iframe>
            </div>
        </div>
    )
}
